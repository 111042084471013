import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule  } from '@angular/http';
import { RouterModule, Routes} from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// AngularFire2 Modules`
import { environment } from '../environments/environment';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireFunctionsModule } from 'angularfire2/functions';
import { FirestoreSettingsToken } from '@angular/fire/firestore';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LocalData, LibsService
       } from './_services';

import { HomeComponent } from './home/home.component';

import { OwlModule } from 'ngx-owl-carousel';
import { LightboxModule } from 'angular2-lightbox';
// import { Ng2PageScrollModule } from 'ng2-page-scroll';
// import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { ContactComponent } from './contact/contact.component';
import { ProductsComponent } from './products/products.component';
import { DetailsComponent } from './products/details/details.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { SupportComponent } from './support/support.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { PartnersComponent } from './partners/partners.component';

import { AccountComponent } from './dashboard/account/account.component';
import { SettingsComponent } from './dashboard/settings/settings.component';
import { OrderComponent } from './dashboard/order/order.component';
import { CartComponent } from './shopping/cart/cart.component';
import { CheckoutComponent } from './shopping/checkout/checkout.component';
import { WishlistComponent } from './dashboard/wishlist/wishlist.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { DashboardMenuComponent } from './dashboard/dashboard-menu/dashboard-menu.component';

const appRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'home',                 redirectTo: '/', pathMatch: 'full'},
  {path: 'contact',              component: ContactComponent},
  {path: 'products',             component: ProductsComponent},
  {path: 'product/:id',          component: DetailsComponent},
  {path: 'support',              component: SupportComponent},
  {path: 'cart',                 component: CartComponent},
  {path: 'checkout',             component: CheckoutComponent},
  // {path: 'mapsearch/:id/:id',    component: MapsearchComponent},
  // {path: 'neighborhood/:id',     component: NeighborhoodComponent},
  // {path: 'sellyourhome',         component: SellyourhomeComponent},
  // {path: 'lending',              component: LendingComponent},
  // {path: 'ultv',                 component: UltvComponent},
  {path: 'aboutus',              component: AboutusComponent},
  {path: 'account',              component: AccountComponent},
  {path: 'wishlist',             component: WishlistComponent},
  {path: 'setting',              component: SettingsComponent},
  // {path: 'activate/:id',         component: ActivateComponent},
  {path: 'order',               component: OrderComponent},
  {path: 'partners',             component: PartnersComponent},
  {path: 'solutions',            component: SolutionsComponent},
  // {path: 'property/:id',         component: PropertyComponent},
  {path: '**',                   redirectTo: '/', pathMatch: 'full'},
]

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    ProductsComponent,
    AboutusComponent,
    SupportComponent,
    SolutionsComponent,
    PartnersComponent,
    AccountComponent,
    SettingsComponent,
    OrderComponent,
    DetailsComponent,
    CartComponent,
    CheckoutComponent,
    WishlistComponent,
    FooterComponent,
    HeaderComponent,
    DashboardMenuComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule.forRoot (appRoutes, { useHash: true }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireDatabaseModule,
    OwlModule,
    LightboxModule,
  ],
  providers: [
    LocalData,
    LibsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
